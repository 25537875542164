.menu {
  position: absolute;
  top: -20px;
  width: 100% !important;
}

.bigToSmallFont {
  font-family: Roboto-Regular, 'Arial Narrow', sans-serif;
  font-size: 12px !important;
  padding-top: 3px;
}

.smallToBigFont {
  font-family: Roboto-Medium, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: normal;
}

.rosterMenuItem {
  padding-top: 1px !important;
  padding-bottom: 4px !important;
}
