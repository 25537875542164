.departed {
  opacity: 0.5;
  background-color: #e8f9f9;
}

.delayed {
  color: #ee710c;
  background-color: #fcebce;
}

.delayed:hover {
  color: #ee710c;
}

.escalated {
  color: #e40300;
  background-color: #fff2f2;
}

.escalated:hover {
  color: #e40300;
}

.inactive {
  opacity: 0.5;
  background-color: #ddd;
}

.icon {
  display: inline-block;
  height: 32px;
  width: 32px;
  background-size: cover;
  background-position: center center;
  background-image: url(../../../../images/dots.gif);
  vertical-align: middle;
}

.departed .icon {
  background-image: url(../../../../images/done.svg);
}

.escalated .icon {
  height: 16px;
  width: 17px;
  margin-left: 8px;
  background-image: url(../../../../images/alert-red.svg);
}

.depOnBlocks .icon {
  background-image: url(../../../../images/dep_onblocks.gif);
}

.depOnBlocks.delayed .icon {
  background-image: url(../../../../images/dep_onblocks_orange.gif);
}

.arrived .icon {
  background-image: url(../../../../images/arrived.svg);
}

.delayed .icon {
  background-image: url(../../../../images/dots-orange.gif);
}

.inactive .icon {
  background-image: url(../../../../images/inactive.svg);
}

.inair .icon {
  background-image: url(../../../../images/inair.gif);
}

.cancelled .icon {
  background-image: url(../../../../images/cancelled.svg);
}

.arrived {
  opacity: 0.5;
  background-color: #e8f9f9;
}
