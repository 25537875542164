.form {
  width: 100%;
}

.center {
  text-align: center;
}

.noticeSection {
  width: 100%;
  margin: 25px 0;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.eventline {
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent calc(24px - 0.51px),
    #dddddd calc(24px - 0.5px),
    #dddddd calc(24px + 0.5px),
    transparent calc(24px + 0.51px),
    transparent 100%);
}

.dot {
  width: 8px;
  height: 8px;
  float: left;
  border-radius: 4px;
  margin-top: 24px;
}

.red {
  background-color: #FF5115;
}

.black {
  background-color: #555555;
}
