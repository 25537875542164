.content {
  position: absolute;
  top: 9px;
  left: 50%;
  margin-left: -215px;
  width: 430px;
  padding: 5px;
	z-index: 500;
  border: 2px solid #ee710c;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 2px;
	text-align: center;
  color: #ee710c;
  background-color: #fcebce;
  cursor: pointer;
}

.refresh {
  display: inline-block;
  margin-left: 4px;
}

.refresh img {
  padding: 0 6px;
}

.refresh span {
  text-decoration: underline;
}
