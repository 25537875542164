.container {
  margin: auto;
  padding-top: 50px;
  text-align: center;

  width: 95%;
}

.form {
  width: 100%;
  text-align: left;
}

.wrapper {
  text-align: center;
  margin: 30px 0 20px 0;
}

.label, .subHeading, .dropdown {
  font-family: Roboto-Regular, 'Arial Narrow', sans-serif !important;
  font-weight: normal !important;
  color: #555555 !important;
}

.link {
  font-family: Roboto-Regular, 'Arial Narrow', sans-serif !important;
  font-weight: normal;
  display: block;
  color: #FF5115;
  margin: 40px 40px 20px;
  text-align: center;
}

.link:hover {
  color: #BB271B !important;
}

.link, .dropdown {
  font-size: 18px !important;
}

.adlink {
  margin-top: 15px;
}

.adlink img {
  padding-left: 4px;
}

.subHeading {
  font-size: 16px !important;
}

.label {
  font-size: 14px !important;
}

.header img {
  vertical-align: middle;
  padding-right: 10px;
}

.success .header {
  margin-top: 20px;
}

.button {
  margin: 30px 0 !important;
}

.eyeIconDefault {
  top: 22px !important;
  height: 0 !important;
}

.eyeIconOnError {
  top: -24px !important;
}
