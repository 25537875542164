.content {
  /* font-size: 14px !important; Must use  !important, otherwise <Segment> will font style */
  /* font-family: Roboto-Bold, Arial, sans-serif Must use  !important, otherwise <Segment> will font style */
}

.role {
  color: #ee7710;
  background-color: #fcebce;
  border-radius: 3px;
  padding: 0 2px;
}
