.dropdown {
  position: absolute !important;
  display: inline-block;
  right: 16px;
  top: -12px;
  padding: 5px 0 5px 10px !important;
  width: 32px !important;
  height: 24px !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #dddddd !important;
}
