.wrapper {
  padding-top: 30px;
  margin: 0 !important;
}

.form {
  width: 100%;
}

.label, .heading {
  font-family: Roboto-Regular, 'Arial Narrow', sans-serif !important;
}

.heading {
  margin: 0;
  margin-bottom: 40px;
}

.button, .label {
  font-size: 14px !important;
}

.button {
  height: 40px;
  width: 160px;
  text-transform: uppercase !important;
}

.label {
  text-align: left;
  font-weight: normal !important;
}
