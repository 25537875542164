.section {
  padding: 10px 20px;
  display: inline-block; /* Must-have so the section is only wide enough for the button, to avoid click issue */
}

.timeline {
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent calc(94px - 2.51px),
    #dddddd calc(94px - 2.5px),
    #dddddd calc(94px + 2.5px),
    transparent calc(94px + 2.51px),
    transparent 100%);
  background-color: #F7F7F7;
}

/* Columns Styling with each row */

.contentCol {
  flex: 1;
  text-align: left;
}

.timestampCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.hoverDisabled {
  pointer-events: none;
}
