.eventRow {
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
}

.eventRow:hover {
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent calc(5px - 0.51px),
    rgba(50, 50, 50, 0.03) calc(5px + 0.51px),
    rgba(50, 50, 50, 0.03) 100%);
}

.completeButton {
  margin-top: 10px !important;
}

/* Dropdown Menu */

.menu {
  display: none;
  position: relative;
}

.eventRow:hover .menu {
  display: inline-block !important;
}

.deleteButton {
  padding: .78571429em 0.8em .78571429em 0.8em !important;
}
