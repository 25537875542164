.wrapper {
  position: relative;
  display:flex;
  flex-direction: column; 
  width: 100%;
}

.message .dropdown {
  font-family: Roboto-Regular !important;
  font-weight: normal !important;
}

.message {
  position: relative;
  height: auto;
  border: 2px solid #fcebce;
  color: #ee710c;
  box-sizing: border-box;
  margin: 10px auto;
  padding: 5px 0px 0px 10px;
  background-color: #fcebce;
  font-size: 14px;
  width: 100%;
}

.message:before{
  position: absolute;
  width: 10px;
  height: 10px;
  border: 0px 2px 2px 0px solid #fcebce;
  top: 18px;
  left: 40px;
  content: '';
  transform: rotate(45deg);
  margin: -25px 0px 0px -25px;
  background-color: #fcebce;
}

.dropdown {
  color: #555555 !important;
  font-size: 18px !important;
}